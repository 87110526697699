.post-blog {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    transition: all .2s ease-in-out; 
  
    text-decoration: none;
    color: $body-color;
    .text-title {
        color: $primary;
    }
    .more-link-post {
        color: $primary;
        font-weight: bold;
    }
     &:hover, &:focus {
        text-decoration: none;
        color: #000;
        .text-title {
             color: #000;
        }
        .more-link-post {
            color: #000;
        }
         
    }
 
 
 
    .foto-image {
       
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        height: 0;
        padding-bottom: 56% !important;
        
        &:before {
            padding-top: 56%;
            display: block;
            content: "";
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            max-width: initial !important;
            border: 0;
            transition: all .2s ease-in-out; 
            transform: translate(-50%, -50%);
            text-align: center;
        }
        

    }
  
}


.categorias-nav {
    list-style: none;
    margin:  0;
    padding:0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    li {
        padding:0.5rem 1rem;
        margin: 0;
        text-transform: uppercase;
        a {
            color: $primary;
            &:hover, &:focus {
                color: $primary;
                text-decoration: none;
            }
        }
        &.current-cat {
            font-weight: bold;
        }

    }
}



#browsing {
    clear: both;
}

.previouspostslink a, .nextpostslink a{
    color: #000;
    font-size: 50px;
    display: flex;
    padding: 5px;
    background: transparent;
    text-decoration: none;
    line-height: 1;
    .icon-rrss {
    }
    &:hover, &:focus {
        background: $primary;
        color: $white;
        text-decoration: none;;
    }
}

.menupostslink a {
    color: #000;
    font-size: 40px;
    display: inline-block;
    padding: 0px;
    background: transparent;
    text-decoration: none;
    line-height: 1;
    &:hover, &:focus {
      
        color: $primary;
        text-decoration: none;;
    }
}

.wp-pagenavi {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center; 
    position: relative;
    padding:0;
    margin: 2rem 0 0 0;
    color: $body-color;
    clear: both;
    line-height: 1;
    a {
       
        padding: 0.5em;
        background: #eee;
        color: $primary;
        //border-right: 1px solid  #d3ced2;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
            background-color: $primary;
        }
    }

    span {
        &:after, &:before {
            //vertical-align: middle;
        }
    }

    .extend {
    }


    .current {
        padding: 0.5em;
        //background: #ebe9eb;
        //color: #8a7e88;
    }

    .page {
        
    }

    .previouspostslink {
        border: 0;
        background-color: #eee;
    }

    .nextpostslink {
        border: 0;
        background-color: #eee;
  
    }
    .menupostslink {
        //font-size: 2rem;
        //line-height: 1;
    }


}
