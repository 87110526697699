// frase_portada
.margin-header {
    margin-top: -150px;
}

.frase_portada {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    .frase_image  {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center bottom;
    }
    .frase_text {
        padding-top: 230px;
        margin: 0 auto 0 auto;
        min-height: 550px;
        max-width: 800px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        position: relative;
    }
    .frase_content, 
    .frase_content h1,
    .frase_content h2,
    .frase_content h3 {
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        color: #747678;
        @include media-breakpoint-up(lg) {
            font-size: 40px;
            line-height: 49px;
        }
    }
}


// Service child grid
.services_child_grid {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    align-items: flex-start;
    justify-content: center;
    margin: 0 -10px;
    .services_child {
        width: 50%;
        padding: 0 10px 20px 10px;
        @include media-breakpoint-up(sm) {
            width: 33.333%;
        }
        @include media-breakpoint-up(md) {
            
            width: 20%;
        }
    }
    
    .service {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        align-items: center;
        background-color: $primary;
        color: #fff;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center center;
        position: relative;
        .tech-bg {
            height: 0;
            width: 100%;
            padding-bottom: 100%;
            background-size: cover;
            background-position: center center;
            //border: 1px solid #b9e0e7;
        }
        .tech-title {
            width: 100%;
            text-align: center;
            //color: #fff;
            margin: 0;
            padding: 0;
            font-size: 1rem;
           // max-width: 130px;
            position: absolute;
       
            z-index: 900;
            color: #fff;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 90%;
            text-align: center;
            p {
                margin: 0;
                line-height: 1.3;
            }
        }
    }  
    
}


// frase_autor

.frase_autor {
    padding: 80px 0;
    margin-bottom: 20px;
    .frase_content,
    .frase_content h1,
    .frase_content h2,
    .frase_content h3 {
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        color: #747678;
        @include media-breakpoint-up(lg) {
            font-size: 40px;
            line-height: 49px;
        }

    }
    .frase_name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #747678;
    }
}




// Sectores
.sectores {
    background-color: #F0F0F0;
    margin-bottom: 20px;
    .sector {
        
        
    }
    .col-sector {
        padding: 20px 30px;
        @include media-breakpoint-up(xl) {
            padding: 20px 80px;
        }
        display: flex;
        align-items: center;
        p {
            margin-bottom: 30px;
        }
    }
    .e-title {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }
        a {
            text-decoration: none;
            color: #747678;
            display: block;
            padding: 5px 0;
            &.current {
                font-weight: bold;
            }
        }
    }
    .box {
        display: none;
        &.current {
            display: block;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}



// frase_destacada
.frase_destacada {
    padding: 40px 0;
    margin-bottom: 20px;
    .frase_content,
    .frase_content p,
    .frase_content h1,
    .frase_content h2,
    .frase_content h3 {
        margin: 0;
        text-align: center;
        color: #747678;
    }

}


// compromisos
.compromisos {
    background-color:  #749DB2;
    color: #fff;
    min-height: 300px;
    padding: 20px 0;
    display: flex;
    .compromiso {
        padding: 10px;
        text-align: center;
    }
    .compromiso-img {
        margin: 0 auto 20px auto;
    }
    .compromiso-title {
        font-size: 16px;
        font-weight: 700;
        max-width: 200px;
        margin: 0 auto;
    }
}




// Nosotros
.nosotros {
    background-color:  #F0F0F0;
    padding: 75px 0 60px 0;
    .box {
        text-align: center;
        padding: 15px;
    }
    .equipo-img {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center center;
        background-color:  #666;
        margin-bottom: 20px;
    }
    .nosotros-intro {
        h1, h2, h3, h4 {
            font-weight: 400;
        }
        p {
            font-weight: 600;
        }
    }
}



// Valores
.valores {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .valor {
        width: 80%;
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
        @include media-breakpoint-up(md) {
            width: 33.333%;
        }
    }
    .valor-bg {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center center;
        background-color:  $primary;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .valor-content {
        max-width: 320px;
        padding: 30px 20px;
        min-height: 240px;
        position: absolute;
        z-index: 900;
        color: #fff;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 90%;
        text-align: center;
        flex-direction: column;
        h1, h2, h3 {
            margin-bottom: 20px;
        }
    }
}




// alianzas
.alianzas {
    background-color:  #F0F0F0;
    padding: 75px 0 75px 0;
    text-align: center;
    .alianzas-title {
        font-size: 30px;
        font-weight: bold;
    }
}

.partners {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .partner {
        width: 25%;
        display: block;
        margin: 15px 0;
        padding: 0 15px;
        text-align: center;
        img {
            max-width: 200px;
            max-height: 80px;
            margin: 0 auto;
        }
    }
}



// servicios_intro
.servicios_intro {
    padding: 75px 0 60px 0;
    margin-bottom: 20px;
    .frase_content,
    .frase_content p,
    .frase_content h1,
    .frase_content h2,
    .frase_content h3 {
        font-size: 18px;
        margin: 0;
        text-align: left;
        color: #747678;
        font-weight: 300;
        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
    }

}


// servicio_imagen
.servicio_imagen {
    max-width: 600px;
    margin: 50px auto;
    img {
        width: 100%;
        height: auto;
    }
}


// servicio_text
.servicio_text {
    margin: 0 auto 50px auto;
}

.servicio_text_gris {
    margin: 0 auto 50px auto;
    padding: 30px;
    background-color:  #F0F0F0;
    @include media-breakpoint-up(lg) {
        padding: 75px;
        margin: 0 auto 75px auto;
    }
}
.vc_col-sm-6 {
    .servicio_text_gris {
        padding: 20px;
        margin: 0 auto 40px auto;
        @include media-breakpoint-up(lg) {
            padding: 40px;
        }
        h4 {
           margin-bottom: 1rem;
        }
    }
}

// page-title
.page-title-bg {
    background-color: $primary;
    color: #fff;
    min-height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
        overflow: hidden;
    a {
        color: #fff;
        text-decoration: none;
    }
    .page-title {
        margin: 30px 0;
        padding: 0;
        text-align: center;
        width: calc(100% - 60px);
    }
    .page-prev {
        padding: 0;
        width: 30px;
        margin: auto auto auto 0;
    }
    .page-next {
        padding: 0;
        width: 30px;
        margin: auto 0 auto auto;
    }
}

//FAQS
.faq_faqs {
    font-size: 16px;
    margin-bottom: 1rem;
    .faq-title {
        margin: 0;
        text-transform: uppercase;
        color: $primary;
        
        
    }
}

.vc_single_image-wrapper  {
    img {
        width: 100%;
    }
}


.map-item {
    width: 100%;
    height: 300px;
    margin-bottom: 4rem;
    @include media-breakpoint-up(lg) {
        height: 400px;
    }
}

