// Utf-8
@charset "utf-8";

// override variables
@import "variables"; // --- Vendors Variables

@import "bootstrap/scss/bootstrap"; // --- Vendors Bootstrap 5

// carousel slides
@import "owl-carousel";
@import 'owl.carousel/scss/owl.carousel';

// Elegant Icons
@import 'elegant-icons/css-generator';

// https://fonts.google.com/icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');