





.footer {
    background-color: $secondary;
    color: #fff;
    a {
        color: #fff;
        text-decoration: none;
        &:hover, &:focus {
            color: #eee;
        }
    }
    .current-page-ancestor .nav-link,
    .current-menu-item .nav-link
    {
        color: #fff !important;
        font-weight: bold;
    }
    .dropdown-item {
        color: $gris-fosc;
    }
    .footer-1 {
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100px;
        @media (min-width: $lg){
            min-height: 150px;
        }
    }
    .footer-2 {
        min-height: 80px;
        padding-top: 20px;
        padding-bottom: 20px;
        @media (min-width: $md){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            padding: 0;
        }
    }
    .navbar-nav-footer {
        
        //display: flex;
        //text-transform: uppercase;
        @media (min-width: $md){
            display: flex;
        }
        li {
            display: flex;
            align-items: center;
            @media (min-width: $md){
        
               
            }
        }
        li:last-child {
            .nav-link {
                //padding-right: 0;
            }
        }
        .nav-link {
            padding: 0.2rem 0rem;
            //color: $primary;
            &:hover, &:focus {
                //color: #000;
            }
            @media (min-width: $md){
                    padding: 0.5rem 1rem;
            }
        }
        
    }

    
 
    .col-logo {
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 20px;
        @media (min-width: $sm){
            padding-bottom: 0;
        }
    }
    .col-menu {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

    }
    .col-newsletter {
        margin-left: auto;
        margin-right: auto;
    }
    
    .col-contact {

    }
    .col-social {
        margin-left: auto;

    }
    .col-legal {
        margin-left: 0;
        margin-top: auto;
        margin-bottom: auto;
        ul {

            @media (min-width: $sm){
                display: flex;
            }
            li:last-child {
                .nav-link {
                    padding-right: 0;
                }
            }
        }
        .nav-link {
            padding: 0.2rem 0rem;
            
            
            @media (min-width: $sm){
                padding: 0.5rem 0.3rem;
            }
            @media (min-width: $md){
                padding: 0.5rem 1rem;
            }
        }
    }
    .col-adress{
        margin-left: 0;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 10px;
        @media (min-width: $sm){
            margin-left: auto;
            padding-top: 0;
        }
        @include media-breakpoint-up(lg) {
            margin-right: 0;
        }
        ul {
            display: inline-block;
            font-weight: bold;
            li {
                display: block;
                @media (min-width: $sm){
                    display: inline-block;
                }
            }
            a {
                padding: 0.5rem 0;
                display: block;
                @media (min-width: $sm){
                    padding: 0.5rem 1rem;
                }
            }
        }
    }

    .rights {
        li {
            //display: inline-block;
        }
    }

    .bg-rights {
        background-color: $primary;
        color: $white;
    
        .current-page-ancestor .nav-link, .current-menu-item .nav-link {
            color: #eee !important;
        }
        
    }
}