// Core
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-moz-selection {
  background: $primary;
  color: white;
}

::selection {
  background: $primary;
  color: white;
}

:-webkit-any-link { color: $primary; }

:-moz-any-link { color: $primary; }

:any-link { color: $primary; }


html, body {
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}


html {
    font-size:  16px;
}

body {
    background-color: $white;
    color: #747678;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    @media (min-width: $lg){
        background-color: $white;

    }
}

.container-service {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 1rem;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}


.text-justify {
    text-align: justify;
}

img {
    max-width: 100%;
    height: auto;
}

b, strong {
    //font-family: 'Futura PT', Arial;
    font-weight: bold;
}

section {
    position: relative;
    width: 100%;
    overflow: hidden;
}

ul li {
    padding-bottom: 1rem;
}

ul.no-margin li, .no-margin ul li {
    padding-bottom: 0;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: $font-title, sans-serif;
    line-height: 1.5;
    font-weight: normal;
}

h1, .h1 {
    font-size: 30px;
    line-height: 39px;
    font-weight: 300;
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 49px;
    }
}

h2, .h2 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 300;
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        font-size: 30px;
        line-height: 37px;
    }
}

h3, .h3 {
    font-size: 18px;
    font-weight: bold;
    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 30px;
    }
}

h4, .h4 {
    font-size: 16px;
    font-weight: bold;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }
}

.btn {

    font-weight: bold;
    min-width: 150px;
    border-radius: 0;
    //line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem 0.2rem 0.75rem;
    font-size: 0.8rem;
    text-align: center;
}
.btn-primary {
    color: #fff;
    background-color: $primary_light;
    border-color: $primary_light;
    &:hover, &:focus {
        color: $primary;
        background-color: #fff;
        border-color: $primary;
    }
}


.btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: $primary_light;
    border-color: $primary_light;
    &:hover, &:focus {
        color: $primary;
        background-color: #fff;
        border-color: $primary;
    }
}

.dropdown-toggle::after {
    display: none;
}

.custom-dropdown-toggle span{
    padding-right: 5px !important;
}

.custom-dropdown-toggle::after {
    display: inline-block;
    padding: 0;
    font-family: "ElegantIcons";
    content: '\33';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    border: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.modal-xl {
    width: 100%;
    padding-right: 32px;
    padding-left: 16px;
    @media (min-width: $sm){
        max-width: 540px;
    }
    @media (min-width: $md){
        max-width: 720px;
    }
    @media (min-width: $lg){
        max-width: 960px;
    }
    @media (min-width: $xl){
        max-width: 1140px;
    }
    @media (min-width: $xxl){
        max-width: 1320px;
    }
}

.modal-header {
    border: 0;
    padding: 0;
}

.modal-header .btn-close {
    padding: 1rem;
    margin: 0 0 0 auto;
    background-color: #fff;
    /* color: #000; */
    position: absolute;
    top: 0;
    right: 0;
}

.modal-body {
    color: #595959;
    padding: 2rem;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin: 0;
    }
}

.bg-gris {
    background-color: #eee;
}

main.content {
    background-color: #fff;
    min-height: calc(100vh - 280px);
    @media (min-width: $lg){
        min-height: calc(100vh - 380px);
    }
}

.text-lighter {
    font-weight: 200 !important;
}

.linkedin {
    fill: #0a66c2;
}




// map
.embed-map-item {
    width: 100%;
    height: 400px;
    @include media-breakpoint-up(md) {
        height: 560px;
    }
    @include media-breakpoint-up(lg) {
        height: 542px;
    }
    @include media-breakpoint-up(xl) {
        height: 508px;
    }
}