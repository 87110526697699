.navbar-toggler {
    //order: 2;
}



.header {
    ul li {padding: 0;}

    .current-page-ancestor .nav-link,
    .current-menu-item .nav-link
    {
        color: $primary !important;
        font-weight: bold;
    }
    .navbar-collapse {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;

        width: 100%;
        background: #fff;
            padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    
        @media (min-width: $lg){
            top: auto;
            left: auto;
            position: relative;
            width: auto;
            background-color: transparent;
            padding-right: 0;
            padding-left: 0;
    
        }
    }


    .navbar {
        padding: 0;
        min-height: 100px;
        background: transparent;
        position: relative;
        z-index: 999;
        @media (min-width: $lg){
            min-height: 150px;
        }
        
    }

    .navbar-nav {
        margin-left: 0;
        margin-bottom: 20px;
        @media (min-width: $lg){
            margin-left: auto;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }
    }

    .navbar-nav .nav-link {
        //text-transform: uppercase;
        font-weight: normal;
        color: $gris-fosc !important;
        @media (min-width: $lg){
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }
        &:hover, &:focus {
            color: rgba(0, 0, 0, 0.55) !important;
        }
    }

}

.lang {
    display: flex;
    margin: 20px 0 20px 0;
    list-style: none;
    padding: 0;
    @media (min-width: $lg){
        order: 2;
        margin: auto 0 auto 1rem;

    }
    li {
        padding: 0;
        a{
            padding: 0.5rem 0.5rem 0.5rem 0;
            text-decoration: none;
            color: $gris-fosc;
            @media (min-width: $lg){
                padding: 0.5rem;
            }

            &:hover, &:focus {
                color: $primary;
            }
        }
        &.active {
            a {
                color: $primary;
            }
        }
        &:last-child {
            a {
                padding-right: 0;
            }
        }
    }
}

.menu-item-has-children {
    @media (min-width: $lg){
        height: 150px;
        display: flex;
        align-items: center;
    }
}
.dropdown-menu {
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;
    background-color: #f0f0f0;
}
.dropdown-item {
    color: $gris-fosc;
    @media (min-width: $lg){
        padding: 0.5rem 1rem;
    }
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: $primary;
    color: #fff;
}