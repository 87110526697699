$primary: #5C7F92;
$primary_light: #749DB2;
$secondary: #486271;
$gris: #f2f2f2;
$gris-fosc: #747678;
$white: #fff;
$black: #000;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;


$font-title:  'Montserrat';

$font-family-base: 'Montserrat';

